import { useLocationMatch } from '@kaliber/routing'
import { useMediaQuery } from '@kaliber/use-media-query'
import { makeSlug } from '/machinery/makeSlug'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { ImageCover } from '/features/buildingBlocks/Image'
import { UppercaseHeadingPlainMd } from '/features/buildingBlocks/UppercaseHeading'
import { SubheadingItalicMd } from '/features/buildingBlocks/Subheading'
import { Button } from '/features/buildingBlocks/Button'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Departments.css'

export function Departments({ departments }) {
  const { __ } = useTranslate()
  const { paginatedDepartments, setPage, page, totalAmount, remainingAmount, hasMore } = useFilterDepartments(departments)

  return (
    <div className={styles.component}>
      <ContainerLg>
        <div className={styles.atsDepartmentsInner}>
          <div className={styles.headingContainer}>
            <SubheadingItalicMd title={__`office`} />
            <UppercaseHeadingPlainMd h='3' title={__`departments`} />
          </div>

          <div className={styles.atsDepartmentCards}>
            {paginatedDepartments.map(department => (
              <Card key={department.id} layoutClassName={styles.cardLayout} {...{ department }} />
            ))}
          </div>
          {hasMore && (
            <div className={styles.loadMoreContainer}>
              <span>{remainingAmount} / {totalAmount}</span>
              <Button dataX={'click-to-view-more'} onClick={() => setPage(page + 1)}>
                {__`load-more`}
              </Button>
            </div>
          )}
        </div>
      </ContainerLg>
    </div>
  )
}

function Card({ department, layoutClassName = undefined }) {
  const { params: { language } } = useLocationMatch()

  return (
    <a
      className={cx(styles.componentCard, layoutClassName)}
      href={routeMap.app.offices.department({ language, slug: department.slug.current })}
      data-x={['link-to-officesDepartmentPage', department.atsDepartment].filter(Boolean).join('-')}
    >
      {department.image && (
        <ImageCover
          aspectRatio={6 / 7}
          image={department.image}
        />
      )}

      {department.title && (
        <div className={styles.titleContainer}>
          <h4 className={styles.titleElement}>
            {department.title}
          </h4>
        </div>
      )}
    </a>
  )
}

function useFilterDepartments(departments) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const [page, setPage] = React.useState(0)
  const PER_PAGE = 8

  const paginatedDepartments = React.useMemo(() => {
    if (isViewportMd) { return departments }
    return departments.slice(0, (page + 1) * PER_PAGE)
  }, [departments, isViewportMd, page])

  const totalAmount = departments.length
  const remainingAmount = paginatedDepartments.length
  const hasMore = totalAmount > remainingAmount

  return {
    paginatedDepartments,
    setPage,
    page,
    totalAmount,
    remainingAmount,
    hasMore
  }
}
